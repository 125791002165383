<template>
  <!-- 新增 -->
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="toggle(false)">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          创建入库
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <div class="itemTime">创建入库</div>
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref='elFormDom'
          label-width='90px'
          label-position='right'
          :model='form.data'
          :rules='form.rules'
          :size="subUnitSize"
          :inline="true"
        >
          <el-form-item label="所在仓库" prop="stash_id">
            <el-select v-model="form.data.stash_id" filterable placeholder="请选择" value-key="id" @change="changeGoods" style="width:280px;"> 
              <el-option
                v-for="item in stashArr"
                :key="item.id"
                :label="`${item.name}-${item.province_str}${item.city_str}`"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-table
          :data="tableData"
          :header-cell-style="{background:'#f5f5f5'}"
          style="width: 100%; margin-top:20px"
          border
          >
          <el-table-column
            width="80">
            <template slot-scope="{}">
              <i class="el-icon-menu"></i>
            </template>
          </el-table-column>
          <el-table-column
            width="80">
            <template slot="header" slot-scope="{}">
              <i class="el-icon-s-fold"></i>
            </template>
            <template slot-scope="{row, $index}">
              <el-popconfirm title="确认删除此商品？" @confirm="onMinus(row,$index)">
                <el-button slot="reference" plain style="width: 22px;height: 18px;padding: 0;">-</el-button>
              </el-popconfirm>
              <el-button plain style="display:block;width: 22px;height: 18px;padding: 0;" @click="onAdd(row,$index)">+</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="good_img"
            label="商品图片"
            width="80px">
            <template slot-scope="{row}">
              <el-image
                v-if="!!row.image"
                style="width: 40px; height: 40px"
                :src="row.image"
                fit="cover"
                :preview-src-list="[row.image]"></el-image>
              <el-image
                v-else
                style="width: 40px; height: 40px"
                :src="require('@/assets/img/logo-icon.jpeg')"
                fit="cover"
                :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="good_name"
            label="商品名称"
            width="180">
            <template slot-scope="{row,$index}">
              <el-select
                v-model="row.good_name"
                filterable
                remote
                placeholder="请输入关键词"
                size="small"
                :remote-method="remoteMethod"
                :loading="selLoading"
                :ref="`multiSelect+${$index}`"
                @change="changeGoods($event,$index)" value-key="id">
                 <el-option v-for="item in goodArr" :label="item.good_name" :value="item" :key="item.id">
                  <div>
                    <span style="float: left">{{ item.good_name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
                  </div>
                  <div style="color:#999;margin-top: -5px;" v-if="!!item.norm_name">标名：{{item.norm_name}}</div>
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            label="商品编码">
          </el-table-column>
          <el-table-column
            prop="cate_name"
            label="商品分类">
          </el-table-column>
          <el-table-column
            prop="unit_name"
            label="单位">
          </el-table-column>
          <el-table-column
            prop="name"
            label="规格">
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="*数量"
            width="140px">
            <template slot-scope="{row}">
              <el-input v-model="row.quantity" placeholder="请输入" size="small" @input="row.quantity=row.quantity.match(/\d+(\.\d{0,5})?/) ? row.quantity.match(/\d+(\.\d{0,5})?/)[0] : ''"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="*单价"
            width="140px">
            <template slot-scope="{row}">
              <el-input v-model="row.price" placeholder="请输入" size="small" @input="row.price=row.price.match(/\d+(\.\d{0,2})?/) ? row.price.match(/\d+(\.\d{0,2})?/)[0] : ''">
                <span slot="suffix">元</span>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="give_date"
            label="*生产日期"
            width="180px">
            <template slot-scope="{row}">
              <el-date-picker
                v-model="row.give_date"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                size="small">
              </el-date-picker>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footerWrap">
        <el-button size="medium" @click="toggle(false)">取消</el-button>
        <el-button type="primary" size="medium" @click="confirm" :loading="form.loading">保存</el-button>
      </div>
    </div>
    
  </div>
</template>

<script>
  import { mixinTable } from "@/mixins/table.js";
  import { getStorage } from '@/storage'
  export default {
    name: 'orderAdd',
    props:['merchantArr'],
    mixins: [mixinTable],
    data() {
      return {
        token: getStorage('token'),
        city: getStorage('city'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        table:{
          params: {
            page:1,
            count: 10,
          },
          total: 0,
          data:[],
        },
        tableData: [{id:'',name:''}],
        specArr: [],
        loading: false,
        form: {
          loading: false,
          data: {
            stash_id: '', //仓库ID
            spec: [
              {
                spec_id: '', //规格ID
                price: '', //价格
                quantity: '', //数量
                give_date: "" //生产日期
              }
            ]
          },
          rules: {
            stash_id: [{ required: true, message:'请选择', trigger: 'change'}],
          },
        },
        currRow: {},
        goodArr: [], // 商品列表
        stashArr: [], // 启用中的仓库
        selLoading: false,
      }
    },
    methods: {
      // 添加订单、采购单、盘点单，列表输入商品名称查询商品
      remoteMethod(query) {
        if(query !== '') {
          this.selLoading = true;
          this.$http.get('/admin/goods/specList', { params:{ page: 1, count: 1000, good_name: query}}).then(res => {
            if(res.code === 1) {
              this.goodArr = res.data.list;
            }
          }).finally(() => {
            this.selLoading = false;
          })
        } else {
          this.goodArr = [];
        }
      },
      getDetail(row) {
        this.currRow = row;
        this.getStash();
      },
      // 获取启用中的仓库
      getStash() {
        this.$http.get('/admin/stash/list', { params:{ page: 1, count: 10000, status:1 }}).then(res => {
          if(res.code == 1) {
            this.stashArr = res.data.list;
            if(res.data.list.length > 0 && !!this.city) {
              this.form.data.stash_id = res.data.list[0].id;
            }
          }
        })
      },
      reset(done) {
        this.tableData = [{id:'',name:''}];
        this.form.data.stash_id = '';
        this.isChange = false
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 减
      onMinus(row,index) {
        console.log(row,index)
        if(this.tableData.length > 1) {
          this.tableData.forEach((v,i) => {
            if(index == i) {
              this.tableData.splice(i, 1)
            }
          })
          this.specArr = this.specArr.filter(j => j != row.id);
        } else {
          this.$message.warning('最后一条数据了!')
        }
      },
      // 加
      onAdd(row,index) {
        let str = {
          image: require('@/assets/img/default.jpg'),
          good_name:'',
          quantity:'',
          price:'',
          give_date:'',
        }
        this.tableData.splice(index+1,0,str); // 添加到指定位置
        this.$set(this.tableData,row,index)
        this.$nextTick(() => {
          this.$refs[`multiSelect+${index + 1}`].focus()
        })
        this.goodArr = []; // 清空上次查询的商品数据
      },
      // 保存
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let currArr = this.tableData.filter(j => !!j.id ) // 过滤掉列表中未选商品的空的数据
            if(currArr.length == 0) {
              this.$message.warning('请先添加商品')
              return
            }
            let _spec = currArr.map(v => {
              return {
                spec_id: v.id,
                quantity: v.quantity,
                price: v.price,
                give_date: v.give_date,
              }
            })
            let _params = {
              stash_id: this.form.data.stash_id, // 仓库id
              spec: _spec
            }
            // 验证数量必填
            let is_num = _spec.some((item) => !item.quantity);
            if(is_num) {
              this.$message.warning("请输入数量！")
              return
            }
            // 验证单价必填
            let is_price = _spec.some((item) => !item.price);
            if(is_price) {
              this.$message.warning("请输入单价！")
              return
            }
            // 验证生产日期必填
            let is_give_date = _spec.some((item) => !item.give_date);
            if(is_give_date) {
              this.$message.warning("请选择生产日期！")
              return
            }
            let num = this.countNum(this.tableData) // 计算收货总数量
            let money = this.countMoney(this.tableData); // 计算收货总金额

            this.$confirm(`商品项数${this.tableData.length}，数量${num}，金额${money}元`, '', {
              confirmButtonText: '确定',
              type: 'warning',
              center: true
            }).then(() => {
              this.form.loading = true;
              this.$http.post('/admin/inventory_log/add', _params).then(res => {
                if(res.code == 1) {
                  this.toggle(false)
                  this.$emit("refresh")
                  this.$message.success('操作成功！')
                }
              }).finally(() => {
                this.form.loading = false;
              })
            }).catch(() => {});
          }
        })
      },
      countNum(arr) {
        let sum = 0;
        arr.map(function (item) {
          sum += Number(item.quantity)
        })
        return sum
      },
      countMoney(arr) {
        let sum = 0;
        arr.map(function (item) {
          sum += parseFloat(Number(item.quantity) * ((item.price) * 100)/100)
        })
        return sum.toFixed(2) * 1
      },
      one(arr) {
        let sum = 0;
        arr.map(function (item) {
          sum += Number(item.num)
        })
        return sum
      },

      // 选择商品
      changeGoods(value, index) {
        let newSpecid = []
        newSpecid = this.tableData.map(v => { return v.id })
        this.specArr = newSpecid;
        if(this.specArr.indexOf(value.id) >= 0) {
          this.$message.warning("已经添加过该规格");
          this.tableData[index].good_name = {}; // 当前行搜索值清空
          this.goodArr = []; // 当前行筛选数据清空
          return; 
        }
        this.specArr.push(value.id);
        this.tableData[index] = value;
        this.$set(this.tableData, index, {...value})
      },
      
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.footerWrap {
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.el-select-dropdown__item {
  display: flex;
  flex-direction: column;
  height: auto !important;
}
</style>



